<script lang="ts">
import { computed, onBeforeUnmount } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseState from '/~/components/base/state/base-state.vue'
import DrawerBankTile from '/~/components/drawer/components/tiles/drawer-bank-tile.v2.vue'
import DrawerCardTile from '/~/components/drawer/components/tiles/drawer-card-tile.v2.vue'
import { useAccess } from '/~/composables/access/use-access'
import { useCms } from '/~/composables/cms/use-cms'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'

export default {
  name: 'payment-methods',
  components: {
    BaseAsidePage,
    BaseButton,
    BaseLoader,
    BaseState,
    DrawerCardTile,
    DrawerBankTile,
  },
  props: {
    to: {
      type: String,
      default: 'menu-modal',
    },
    close: {
      type: Function,
      default: null,
    },
    verifyModalTitle: {
      type: String,
      default: null,
    },
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    const {
      fetchingList: fetching,
      creditCards,
      bankAccounts,
      noCreditCards,
      noBankAccounts,
      methodToVerify,
      isMethodAvailable,
      hasUnavailableMethods,
    } = usePaymentMethods()

    const { withAccessCheck } = useAccess()

    const { emptyPaymentMethodsConfig } = useCms()

    const isEmpty = computed(() => {
      return !fetching.value && noCreditCards.value && noBankAccounts.value
    })

    const emptyState = computed(() => ({
      image: emptyPaymentMethodsConfig.value?.emptyMethodsImage?.desktop
        ? emptyPaymentMethodsConfig.value.emptyMethodsImage.desktop
        : '/empty/empty-payment-methods.svg',
      title: emptyPaymentMethodsConfig.value?.emptyMethodsTitle
        ? emptyPaymentMethodsConfig.value.emptyMethodsTitle
        : 'Add your Payment Methods',
      description: emptyPaymentMethodsConfig.value?.emptyMethodsDescription
        ? emptyPaymentMethodsConfig.value.emptyMethodsDescription
        : 'Securely store and manage your preferred credit, debit and nominated bank accounts for instant use.',
      alt: emptyPaymentMethodsConfig.value?.emptyMethodsImage?.alt
        ? emptyPaymentMethodsConfig.value.emptyMethodsImage.alt
        : '',
    }))

    const backRoute = route.meta?.back ?? {
      hash: '#profile-home',
    }

    function goToPaymentMethodsAdd() {
      if (fetching.value) {
        return
      }

      withAccessCheck({
        callback: () => {
          const addRoute = route.meta?.add ?? {
            hash: '#profile-payment-methods-add',
          }

          router.push(addRoute)
        },
        config: {
          actionType: 'paymentMethods',
        },
      })
    }

    onBeforeUnmount(() => {
      methodToVerify.value = null
    })

    return {
      fetching,
      creditCards,
      bankAccounts,
      isMethodAvailable,
      isEmpty,
      backRoute,
      goToPaymentMethodsAdd,
      hasUnavailableMethods,
      PaymentMethodType,
      emptyState,
      withAccessCheck,
    }
  },
}
</script>

<template>
  <base-aside-page
    title="Payment Methods"
    :action="!hasUnavailableMethods() && goToPaymentMethodsAdd"
    :back="backRoute"
    no-padding
  >
    <base-state
      v-if="isEmpty"
      :title="emptyState.title"
      :image="emptyState.image"
      :alt="emptyState.alt"
      class="min-h-full px-5 pb-5"
    >
      <div v-html="emptyState.description" />

      <template #footer>
        <base-button @click="goToPaymentMethodsAdd">
          Add Payment Method
        </base-button>
      </template>
    </base-state>

    <div v-else class="space-y-5 pb-5 pt-2.5">
      <div
        v-if="fetching"
        class="flex w-full items-center justify-center py-[15px]"
      >
        <base-loader />
      </div>
      <template v-if="isMethodAvailable(PaymentMethodType.creditCard)">
        <drawer-card-tile
          v-for="card in creditCards"
          :key="card.id"
          :card="card"
        />
      </template>
      <template v-if="isMethodAvailable(PaymentMethodType.bankAccount)">
        <drawer-bank-tile
          v-for="account in bankAccounts"
          :key="account.id"
          :account="account"
        />
      </template>
    </div>
  </base-aside-page>
</template>
